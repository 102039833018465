.p_nama_barang_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
    font-size: 1.02em;
    font-weight: 500;
}
.p_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.2em;
    font-weight: 600;
}
.p_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #bc1c1c;
    font-size: 1em;
}
.p_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 1em;
    font-weight: bold;
}
.p_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #e25757;
    font-size: 1.2em;
    font-weight: bold;
}

/*search*/
.src_p_nama_barang_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
    font-size: 1.02em;
    line-height: 1.5em;
    min-height: 3em;
}
.src_p_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.src_p_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #bc1c1c;
    font-size: 0.9em;
}
.src_p_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 0.9em;
    font-weight: bold;
}
.src_p_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.src_p_property_barang_text {
    margin-top: 3px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    color: #3f3f3f;
}

/* card */
.card.border {
    background:white;
    border-radius: 12px !important;
}
.card.shadow-item {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0,0.1);
    
}