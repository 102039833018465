.pd_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #e25757;
    font-size: 1.5em;
    font-weight: bold;
}
.pd_property_barang_text {
    font-family: 'Roboto', sans-serif;
    color: #3f3f3f;
    font-size: 1.1em;
}
.pd_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    color: #e25757;
    font-size: 1.1em;
    font-weight: bold;
}
.pd_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #3f3f3f;
    font-size: 1.2em;
    font-weight: bold;
}
.pd_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    color: #e25757;
    font-size: 1.5em;
    font-weight: bold;
}
.nama_barang_text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25em;
}

p.download-apps-text {
    margin-top: 25px;

}

.playstore-img {
    width: 150px;
    height: auto;
}
.delivery-area {
    margin-top: 25px;
}
hr.styling {
    margin-top: 30px;
    margin-bottom: 30px;
}
.product-info-tab .title {
    font-size: 1.2em;
}
.product-info-tab .message {
    font-size: 1em;
}