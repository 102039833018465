:root {
--gutter: 20px;
}

.nata-hs-fl-container {
padding: 0;
display: grid;
grid-gap: var(--gutter);
grid-template-columns: var(--gutter) 1fr var(--gutter);
align-content: start;
width: 100%;
height: auto;
overflow-y: hidden;
}

.nata-hs-fl-container > * {
grid-column: 2 / -2;
}

.nata-hs-fl-container > .full {
grid-column: 1 / -1;
}

.hs-fl {
    display: grid;
  gap: calc(var(--gutter) / 2);
  /* grid-template-columns: 10px repeat(var(--total-fl), calc(100% - var(--gutter) * 2)) 10px; */
  grid-template-columns: 0 repeat(var(--total-fl), calc(180px - var(--gutter) * 2));
  grid-template-rows: minmax(150px, 1fr);
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  /* padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter)); */
}
.hs-fl::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.hs-fl:before,
.hs-fl:after {
  content: '';
}

/*demo*/
ul.nata-hs-ul-fl {
    list-style: none;
    padding: 0;
  }

.hs-fl > li,
.nata-item-fl {
    /* scroll-snap-align: start; */
  padding: 0;

  /* padding: calc(var(--gutter) / 2 * 1.5); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
}