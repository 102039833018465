.search_pagination {
    margin-top: 20px;
}
.search-input {
    color:#2b2b2b !important;
    background-color: #f2f2f2 !important;
    border: 0 !important;
    border-radius: 10px !important;
}
.search-input-active {
  color:#2b2b2b !important;
  background-color: #fff !important;
  outline: none !important;
  border: 0 !important;
}
.search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
  
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #555454 !important;
  }
  
.search-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #555454 !important;
  }

.search_div {
  z-index: 110;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 54px);
  display: flex;
  min-height: 0;
  position: absolute;
}

.scrollable-content {
  background: white;
  overflow-y: scroll;
  flex: 1 1 auto;
  /* flex-basis: calc(100% - 56px); */
  
  /* for Firefox */
  min-height: 0;
}
.scrollable-content .suggestion-items {
  color: #2b2b2b;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-right: 3px;
}
.scrollable-content::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */

.ui.fixed.menu {
  z-index: 120;
}