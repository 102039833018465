.box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.eq-card.ui.card {
    height: 100px;
}

/* .card.card-margin {
    margin: 5px;
} */
/* .ui.cards.fill {
    width: 100% !important;
} */

.card.card-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.10) !important;
}

.img-parent {
    display: -ms-flex; display: -webkit-flex; display: flex;
}
img.eq-image {
    height: 100px;
    object-fit: cover;
    border-radius: 20% !important;
    border-collapse: separate; 
}

.featured-kat-text {
    background-color: #f2f2f2;
    color: #2b2b2b;
    z-index: 100;
    position: absolute;
    padding: 8px;
    left: 0;
    bottom: 0;
    font-weight: bold;
}

.featured-kat-semua-kategori {
    margin: auto;
    color: #2b2b2b;
    text-align: center;
    font-weight: 600;
}