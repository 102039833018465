@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Open+Sans:600|Roboto&display=swap);
.search_pagination {
    margin-top: 20px;
}
.search-input {
    color:#2b2b2b !important;
    background-color: #f2f2f2 !important;
    border: 0 !important;
    border-radius: 10px !important;
}
.search-input-active {
  color:#2b2b2b !important;
  background-color: #fff !important;
  outline: none !important;
  border: 0 !important;
}
.search-input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
.search-input::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
.search-input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
.search-input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
.search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555454 !important;
    opacity: 1 !important; /* Firefox */
  }
  
.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #555454 !important;
  }
  
.search-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #555454 !important;
  }

.search_div {
  z-index: 110;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 54px);
  display: flex;
  min-height: 0;
  position: absolute;
}

.scrollable-content {
  background: white;
  overflow-y: scroll;
  flex: 1 1 auto;
  /* flex-basis: calc(100% - 56px); */
  
  /* for Firefox */
  min-height: 0;
}
.scrollable-content .suggestion-items {
  color: #2b2b2b;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-right: 3px;
}
.scrollable-content::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */

.ui.fixed.menu {
  z-index: 120;
}
:root {
--gutter: 20px;
}

.nata-hs-container {
padding: 0;
display: grid;
grid-gap: 20px;
grid-gap: var(--gutter);
grid-template-columns: 20px 1fr 20px;
grid-template-columns: var(--gutter) 1fr var(--gutter);
align-content: start;
width: 100%;
height: auto;
overflow-y: hidden;
}

.nata-hs-container > * {
grid-column: 2 / -2;
}

.nata-hs-container > .full {
grid-column: 1 / -1;
}

.hs {
    display: grid;
  grid-gap: calc(20px / 2);
  gap: calc(20px / 2);
  grid-gap: calc(var(--gutter) / 2);
  gap: calc(var(--gutter) / 2);
  /* grid-template-columns: 10px repeat(var(--total), calc(100% - var(--gutter) * 2)) 10px; */
  grid-template-columns: 0px repeat(var(--total), calc(100% - 20px * 2)) 0px;
  grid-template-columns: 0px repeat(var(--total), calc(100% - var(--gutter) * 2)) 0px;
  grid-template-rows: minmax(150px, 1fr);
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  /* padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter)); */
}
.hs::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.hs:before,
.hs:after {
  content: '';
}

/*demo*/
ul.nata-hs-ul {
    list-style: none;
    padding: 0;
  }

.hs > li,
.nata-item {
    /* scroll-snap-align: start; */
  padding-left: 0px;

  /* padding: calc(var(--gutter) / 2 * 1.5); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
}
.box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.eq-card.ui.card {
    height: 100px;
}

/* .card.card-margin {
    margin: 5px;
} */
/* .ui.cards.fill {
    width: 100% !important;
} */

.card.card-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.10) !important;
}

.img-parent {
    display: -ms-flex; display: flex;
}
img.eq-image {
    height: 100px;
    object-fit: cover;
    border-radius: 20% !important;
    border-collapse: separate; 
}

.featured-kat-text {
    background-color: #f2f2f2;
    color: #2b2b2b;
    z-index: 100;
    position: absolute;
    padding: 8px;
    left: 0;
    bottom: 0;
    font-weight: bold;
}

.featured-kat-semua-kategori {
    margin: auto;
    color: #2b2b2b;
    text-align: center;
    font-weight: 600;
}
:root {
--gutter: 20px;
}
h3.top-picks {
  margin-left: 10px;
}

.nata-hs-fk-container {
padding: 0;
display: grid;
grid-gap: 20px;
grid-gap: var(--gutter);
grid-template-columns: 20px 1fr 20px;
grid-template-columns: var(--gutter) 1fr var(--gutter);
align-content: start;
width: 100%;
height: auto;
overflow-y: hidden;
}

.nata-hs-fk-container > * {
grid-column: 2 / -2;
}

.nata-hs-fk-container > .full {
grid-column: 1 / -1;
}

.hs-fk {
    display: grid;
  grid-gap: calc(20px / 2);
  gap: calc(20px / 2);
  grid-gap: calc(var(--gutter) / 2);
  gap: calc(var(--gutter) / 2);
  /* grid-template-columns: 10px repeat(var(--total-fk), calc(100% - var(--gutter) * 2)) 10px; */
  grid-template-columns: 0 repeat(var(--total-fk), calc(180px - 20px * 2));
  grid-template-columns: 0 repeat(var(--total-fk), calc(180px - var(--gutter) * 2));
  grid-template-rows: minmax(150px, 1fr);
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  /* padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter)); */
}
.hs-fk::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.hs-fk:before,
.hs-fk:after {
  content: '';
}

/*demo*/
ul.nata-hs-ul-fk {
    list-style: none;
    padding: 0;
  }

.hs-fk > li,
.nata-item-fk {
    /* scroll-snap-align: start; */
  padding: 0;

  /* padding: calc(var(--gutter) / 2 * 1.5); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
}
.p_nama_barang_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
    font-size: 1.02em;
    font-weight: 500;
}
.p_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.2em;
    font-weight: 600;
}
.p_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #bc1c1c;
    font-size: 1em;
}
.p_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 1em;
    font-weight: bold;
}
.p_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #e25757;
    font-size: 1.2em;
    font-weight: bold;
}

/*search*/
.src_p_nama_barang_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
    font-size: 1.02em;
    line-height: 1.5em;
    min-height: 3em;
}
.src_p_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.src_p_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #bc1c1c;
    font-size: 0.9em;
}
.src_p_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    color: #3f3f3f;
    font-size: 0.9em;
    font-weight: bold;
}
.src_p_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 3px;
    color: #e25757;
    font-size: 1.05em;
    font-weight: bold;
}
.src_p_property_barang_text {
    margin-top: 3px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    color: #3f3f3f;
}

/* card */
.card.border {
    background:white;
    border-radius: 12px !important;
}
.card.shadow-item {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0,0.1);
    
}
:root {
--gutter: 20px;
}

.nata-hs-fl-container {
padding: 0;
display: grid;
grid-gap: 20px;
grid-gap: var(--gutter);
grid-template-columns: 20px 1fr 20px;
grid-template-columns: var(--gutter) 1fr var(--gutter);
align-content: start;
width: 100%;
height: auto;
overflow-y: hidden;
}

.nata-hs-fl-container > * {
grid-column: 2 / -2;
}

.nata-hs-fl-container > .full {
grid-column: 1 / -1;
}

.hs-fl {
    display: grid;
  grid-gap: calc(20px / 2);
  gap: calc(20px / 2);
  grid-gap: calc(var(--gutter) / 2);
  gap: calc(var(--gutter) / 2);
  /* grid-template-columns: 10px repeat(var(--total-fl), calc(100% - var(--gutter) * 2)) 10px; */
  grid-template-columns: 0 repeat(var(--total-fl), calc(180px - 20px * 2));
  grid-template-columns: 0 repeat(var(--total-fl), calc(180px - var(--gutter) * 2));
  grid-template-rows: minmax(150px, 1fr);
  
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  /* padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter)); */
}
.hs-fl::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.hs-fl:before,
.hs-fl:after {
  content: '';
}

/*demo*/
ul.nata-hs-ul-fl {
    list-style: none;
    padding: 0;
  }

.hs-fl > li,
.nata-item-fl {
    /* scroll-snap-align: start; */
  padding: 0;

  /* padding: calc(var(--gutter) / 2 * 1.5); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
}
.pd_harga_barang_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #e25757;
    font-size: 1.5em;
    font-weight: bold;
}
.pd_property_barang_text {
    font-family: 'Roboto', sans-serif;
    color: #3f3f3f;
    font-size: 1.1em;
}
.pd_diskon_condition_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    color: #e25757;
    font-size: 1.1em;
    font-weight: bold;
}
.pd_dsc_harga_barang_normal_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #3f3f3f;
    font-size: 1.2em;
    font-weight: bold;
}
.pd_dsc_harga_barang_after_diskon_text {
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    color: #e25757;
    font-size: 1.5em;
    font-weight: bold;
}
.nama_barang_text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25em;
}

p.download-apps-text {
    margin-top: 25px;

}

.playstore-img {
    width: 150px;
    height: auto;
}
.delivery-area {
    margin-top: 25px;
}
hr.styling {
    margin-top: 30px;
    margin-bottom: 30px;
}
.product-info-tab .title {
    font-size: 1.2em;
}
.product-info-tab .message {
    font-size: 1em;
}
.pengumuman-card {
    padding: 12px;
    margin-top: 20px;
}
h1.pengumuman-title {
    font-size: 1.4em;
    margin-bottom: 20px;
}

.linimasa-info {
    margin-bottom: 20px;
}
.linimasa-info p {
    font-size: 1.1em;
}
a.sub_kategori {
    color: #2b2b2b;
}

a.sub_kategori:hover {
    color: #bc1c1c
}

/*detail*/
.result_keterangan {
    margin-bottom: 10px;
}
body {
    font-family: 'Roboto', sans-serif;
}
.main.container {
margin-top: 7em;
}
.wireframe {
margin-top: 2em;
}
.seo-home {
    font-size: 0.85em;
}
h3.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
}
