a.sub_kategori {
    color: #2b2b2b;
}

a.sub_kategori:hover {
    color: #bc1c1c
}

/*detail*/
.result_keterangan {
    margin-bottom: 10px;
}